import React, { useRef } from 'react';

import { useInView } from 'framer-motion';

//**import shared components */
import Card from '@src/components/shared/Card';
import Typography from '@src/components/shared/Typography';
import Image from '@src/components/shared/Image';

//**import assets */
import coding from '@assets/svg/services/coding.svg';
import hourGlass from '@assets/svg/services/hourGlass.svg';
import idea from '@assets/svg/services/idea.svg';
import onlineSupport from '@assets/svg/services/onlineSupport.svg';
import { bottom_top_variants } from '@src/utils/animations';

const ChooseUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  //**dummy data */
  const servicesData = [
    {
      img: coding,
      title: 'Proven Delivery Framework',
    },
    {
      img: hourGlass,
      title: 'Real World Experience',
    },
    {
      img: idea,
      title: 'Customer Centric Delivery Model',
    },
    {
      img: onlineSupport,
      title: '24/7 Dedicated support',
    },
  ];

  return (
    <div className="bg-light-blue p-8">
      <div>
        <div ref={ref}>
          <div className="overflow-hidden">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0,
              }}
              variant="P_SemiBold_H3"
              className="flex justify-center text-primary-black"
            >
              Why Choose ABTECH?
            </Typography>
          </div>
          <div className="overflow-hidden">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0.1,
              }}
              variant="P_Regular_H6"
              className="flex justify-center text-mid-black text-center"
            >
              
            </Typography>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-1 gap-8 md:px-16 mt-12 sm:grid-cols-2 lg:grid-cols-4 md:mt-20">
            {servicesData.map((item, idx) => (
              <Card
                idx={idx}
                delay={0}
                key={idx}
                className="overflow-hidden p-0 bg-primary-white rounded-xl !shadow-lg"
                clickable
              >
                <div className="px-2">
                  <Image
                    className="md:w-32 mx-auto flex-shrink-0 flex  items-center"
                    src={item.img}
                    alt={item.title}
                  />
                  <div className="flex flex-col">
                    <Typography
                      variant="P_SemiBold_H5"
                      className="text-light-black mt-4 text-center"
                    >
                      {item.title}
                    </Typography>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
