import React, { useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

//**import components */
import Typography from '../shared/Typography';
import Button from '../shared/Button';

// animations
import {
  left_right_variants,
  top_bottom_variants,
} from '@src/utils/animations';
import { useNavigate } from 'react-router-dom';

function OurExperience({ flip }) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const navigate = useNavigate();

  return (
    <section
      className={`pt-6 ${flip ? 'bg-primary-white' : 'bg-light-blue'} w-full`}
    >
      <div className="px-4 mx-auto w-10/12  sm:px-6 lg:px-0">
        <div
          ref={ref}
          className="grid grid-cols-1 gap-y-12 lg:grid-cols-12 gap-x-16 "
        >
          {flip ? (
            <>
              <div className=" lg:col-span-7 flex max-sm:flex-col gap-8">
                <div className=" inline-flex flex-col gap-8 flex-1">
                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0}
                      variants={top_bottom_variants}
                      className="overflow-hidden text-mid-black w-full bg-light-blue flex items-center  h-[200px] rounded-xl"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H2"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          250
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className=" font-normal leading-tight"
                        >
                          Global Customer
                        </Typography>
                      </div>
                    </m.div>
                  </div>

                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0.2}
                      variants={top_bottom_variants}
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      className="overflow-hidden text-mid-black bg-light-blue rounded-xl flex items-center h-[200px] w-full"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H2"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          50+
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className="font-normal leading-tight"
                        >
                          Team Member
                        </Typography>
                      </div>
                    </m.div>
                  </div>
                </div>

                <div className="inline-flex flex-col gap-8 flex-1 md:mt-8">
                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0.1}
                      variants={top_bottom_variants}
                      className="overflow-hidden text-mid-black w-full bg-light-blue flex items-center h-[200px] rounded-xl"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H2"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          156+
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className=" font-normal leading-tight"
                        >
                          Project Completed
                        </Typography>
                      </div>
                    </m.div>
                  </div>
                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0.4}
                      variants={top_bottom_variants}
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      className="overflow-hidden w-full text-mid-black bg-light-blue rounded-xl flex items-center h-[200px]"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H2"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          15+
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className="font-normal leading-tight"
                        >
                          Our Company
                        </Typography>
                      </div>
                    </m.div>
                  </div>
                </div>
              </div>

              <div className="row-start-1 row-end-4 md:row-auto overflow-hidden  lg:col-span-5">
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.5,
                  }}
                  className="mt-6 text-base font-normal block"
                >
                  Our Expereince
                </Typography>
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.6,
                  }}
                  variant="P_SemiBold_H3"
                  className="block  text-mid-black"
                >
                  Lorem ipsum dolor
                </Typography>
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.7,
                  }}
                  variant="P_SemiBold_H3"
                  className=" text-primary-blue block"
                >
                  sit amet consectetur.
                </Typography>
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.8,
                  }}
                  variant="P_Regular_H6"
                  className="mt-7 block"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nascetur imperdiet at a augue ullamcorper. Leo faucibus
                  feugiat rutrum aenean. Nisi, lectus aliquet aliquam
                  consectetur augue praesent. Lorem vulputate orci eget mi, sed
                  pulvinar.
                </Typography>
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.8,
                  }}
                  variant="P_Regular_H6"
                  className="mt-3 block"
                >
                  Ornare etiam erat volutpat tempor fringilla mi. Elit a blandit
                  faucibus est, dui interdum ut amet. Adipiscing feugiat vel at
                  posuere in. Pellentesque volutpat vestibulum.
                </Typography>
                <m.div
                  initial="initial"
                  animate={isInView && 'slidein'}
                  custom={0.9}
                  variants={left_right_variants}
                  className="flex my-8 items-center space-x-3 sm:space-x-4"
                >
                  <Button onClick={() => navigate('/contact-us')}>
                    Contact Us
                  </Button>
                </m.div>
              </div>
            </>
          ) : (
            <>
              <div className="lg:col-span-5 overflow-hidden">
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0,
                  }}
                  className="mt-0 text-base font-normal block"
                ></Typography>
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.1,
                  }}
                  variant="P_SemiBold_H3"
                  className="block  text-mid-black"
                ></Typography>
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.2,
                  }}
                  variant="P_SemiBold_H4"
                  className=" text-primary-blue block"
                >
                  Backed by expertise
                </Typography>
                <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.3,
                  }}
                  variant="P_Regular_H6"
                  className="block"
                >
                  Backed by expertise from thousands of man-years of successful
                  implementations worldwide and Domain Expertise, ABTECH
                  works directly with customers to overcome today’s challenges,
                  combining the right technologies and skill sets. We approach
                  enterprise application development by putting the user
                  experience in high priority.
                  <Typography
                    mAnimate={{
                      mVariants: left_right_variants,
                      isInView: isInView,
                      mDelay: 0.2,
                    }}
                    variant="P_SemiBold_H4"
                    className=" text-primary-blue block"
                  >
                    Driving Transformation
                  </Typography>
                  ABTECH can help you maximize value from your applications
                  and drive digital transformation. Our Application Managed
                  Services give you the option of allowing to manage the
                  applications you create using a business-centric service level
                  agreement designed to surpass in-house service, at a lower
                  cost..
                </Typography>
                {/* <Typography
                  mAnimate={{
                    mVariants: left_right_variants,
                    isInView: isInView,
                    mDelay: 0.4,
                  }}
                  variant="P_Regular_H6"
                  className="block"
                >
                  .
                </Typography> */}
                <m.div
                  initial="initial"
                  animate={isInView && 'slidein'}
                  custom={0.5}
                  variants={left_right_variants}
                  className="flex my-8 items-center space-x-3 sm:space-x-4"
                >
                  <Button onClick={() => navigate('/contact-us')}>
                    Contact Us
                  </Button>
                </m.div>
              </div>

              <div className='col-span-7 py-8 '>
              <Typography
                          variant="P_Bold_H2"
                          className=" mb-3 text-center font-bold text-mid-black block text-amber-500"
                        >
                          Industry focus
                        </Typography>

              <div className="lg:col-span-7 flex max-sm:flex-col gap-8">
                
                <div className=" inline-flex flex-col gap-8 flex-1">
                  <div className="inline-flex overflow-hidden">
                    <Typography
                      mAnimate={{
                        // mVariants: bottom_top_variants,
                        isInView: isInView,
                        mDelay: 0,
                      }}
                      variant="P_SemiBold_H3"
                      className="text-primary-black text-blue-600"
                    ></Typography>
                  </div>
                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0.5}
                      variants={top_bottom_variants}
                      className="overflow-hidden text-mid-black w-full bg-primary-white flex items-center  h-[100px] rounded-xl"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H4"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          Utilities
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className=" font-normal leading-tight"
                        ></Typography>
                      </div>
                    </m.div>
                  </div>

                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0.7}
                      variants={top_bottom_variants}
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      className="overflow-hidden text-mid-black bg-primary-white rounded-xl flex items-center h-[100px] w-full"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H4"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          Logistics
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className="font-normal leading-tight"
                        ></Typography>
                      </div>
                    </m.div>
                  </div>
                </div>

                <div className="inline-flex flex-col gap-8 flex-1 md:mt-8">
                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0.6}
                      variants={top_bottom_variants}
                      className="overflow-hidden text-mid-black w-full bg-primary-white flex items-center h-[100px] rounded-xl"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H4"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          Capital Project Management
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className=" font-normal leading-tight"
                        ></Typography>
                      </div>
                    </m.div>
                  </div>
                  <div className="inline-flex overflow-y-hidden p-2">
                    <m.div
                      initial="initial"
                      animate={isInView && 'visible'}
                      custom={0.8}
                      variants={top_bottom_variants}
                      style={{
                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
                      }}
                      className="overflow-hidden w-full text-mid-black bg-primary-white rounded-xl flex items-center h-[100px]"
                    >
                      <div className="mx-auto">
                        <Typography
                          variant="P_Bold_H4"
                          className=" mb-3 text-center font-bold text-mid-black block"
                        >
                          SmartCities
                        </Typography>
                        <Typography
                          variant="P_Medium_H5"
                          className="font-normal leading-tight"
                        ></Typography>
                      </div>
                    </m.div>
                  </div>
                  <div className="inline-flex overflow-hidden"></div>
                </div>
              </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
export default OurExperience;
