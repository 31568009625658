import React from 'react';
import { motion } from 'framer-motion';

// import components
import Typography from '@src/components/shared/Typography';
import Button from '@src/components/shared/Button';
import { bottom_top_variants } from '@src/utils/animations';
import { Link } from 'react-router-dom';

const Rewrite = () => {
  return (
    <div className="w-full bg-primary-blue py-16 px-10 xs:px-20">
      <div className="max-w-7xl mx-auto">
        <div className="grid xl:grid-cols-4 md:grid-cols-3 ms:grid-cols-2 grid-cols-1 gap-6">
          <motion.div
            initial="initial"
            whileInView="visible"
            variants={bottom_top_variants}
            custom={0 + 0 * 0.08}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            className="p-10 bg-white rounded-[10px]"
          >
            <Typography variant="P_SemiBold_H3" className="text-primary-blue">
              Idea
            </Typography>
            <p className="font-poppins font-semibold text-[14px] md:text-[16px] tracking-normal">
              Provide Corporate Governance, Help Clients Manage Operations Seamlessly. 
            </p>
          </motion.div>
          <motion.div
            initial="initial"
            whileInView="visible"
            variants={bottom_top_variants}
            custom={0 + 0 * 0.08}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            className="p-10 bg-white rounded-[10px]"
          >
            <Typography variant="P_SemiBold_H3" className="text-primary-blue">
              Philosophy
            </Typography>
            <p className="font-poppins font-semibold text-[14px] md:text-[16px] tracking-normal">
              ABTECH's complete range of IT Services addresses the needs of both technology and business requirements to help organizations leverage new-age leading-edge technologies for business establishment, improvement.
            </p>
          </motion.div>
          <motion.div
            initial="initial"
            whileInView="visible"
            variants={bottom_top_variants}
            custom={0 + 0 * 0.08}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            className="p-10 bg-white rounded-[10px]"
          >
            <Typography variant="P_SemiBold_H3" className="text-primary-blue">
              Focus
            </Typography>
            <p className="font-poppins font-semibold text-[14px] md:text-[16px] tracking-normal">
            ABTECH focuses on making a genuine impact on our clients and their customers through innovative digital services, business solutions and design-led experiences, delivered through the power of people and the Technology Eco-System.
            </p>
          </motion.div>
          <motion.div
            initial="initial"
            whileInView="visible"
            variants={bottom_top_variants}
            custom={0 + 0 * 0.08}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            className="p-10 bg-white rounded-[10px]"
          >
            <Typography variant="P_SemiBold_H3" className="text-primary-blue">
              Leadership
            </Typography>
            <p className="font-poppins font-semibold text-[14px] md:text-[16px] tracking-normal">
            With passion for Information Technology , ABTECH's Leadership is responsible for the information technology (IT) infrastructure & applications that enable and drive the overarching business strategy and goals with excellence.
            </p>
          </motion.div>
        </div>
        <div className="flex items-center justify-between mt-8 sm:flex-row flex-col">
          <div className="sm:w-[100%] w-full text-center">
            <Typography variant="P_SemiBold_H6" className="text-white">
            When you work with ABTECH, not only you beneﬁt from our deep technologies expertise, but you also gain a strategic partner that can help you address today’s business challenges and prepare for tomorrow. Our team has a remarkable mix of skills, Business Acumen, Industry experience and program management skills  that enhance your competitive edge. Working together with you, we’ll deliver the Enterprise Business Solutions that results in Value Co-Creation, Excellence and Business Success.
            </Typography>
          </div>
          {/* <Link to="/contact-us">
            <Button className="!bg-[#17C229] mt-10">Book Consulting</Button>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Rewrite;
