import React, { useRef } from 'react';

import { useInView } from 'framer-motion';

// ** import components
import Hero from '@src/components/Hero';

// ** import image
import bg from '@assets/Images/services/Headerbg.jpg'

function HeaderBanner() {

  return (
    <Hero img={bg} status='services' headerContent={'Tech Excellence, Tailored for You'} content={'ABTECH is a leading provider of Integrated business, technology and process solutions on a global delivery platform.     Developing tailored strategies to magnify and bring out the full potential of your one-of-a-kind vision.      Your Partner by Choice.'} />
     );
}
export default HeaderBanner;
