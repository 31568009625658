import React, { useRef } from 'react';
import { toast } from 'react-hot-toast';



// ** import logo
import logo from '@assets/icon/navLogo.png';

// ** import social icons
import instagramSvg from '@assets/icon/social/instagram.svg';
import twitterSvg from '@assets/icon/social/twitter.svg';
import googleSvg from '@assets/icon/social/google.svg';
import facebookSvg from '@assets/icon/social/facebook.svg';
import linkedinSvg from '@assets/icon/social/linkedin.svg';

// ** import Components
import Image from '@shared/Image';
import Button from '@shared/Button';
import Typography from '@shared/Typography';
import EmailInput from '../shared/EmailInput';
import { useState } from 'react';
import ContactForm from '../BronchureForm/contactform';
import axios from '../../axios';

function Footer() {
  const [popUp, setpopUp] = useState(false)
  const [email, setEmail] = useState('')


  const onSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailRegex.test(email)) {
      const { data } = await axios.post('/collect-email', {
        email: email
      })

      if (data.success) {
        setEmail("")
        return toast.success(`${data.message}`)
      } else {
        return toast.error(`${data.message}`)

      }
    } else {
      return toast.error('Invalid email');
    }

  };
  return (
    <footer className="pt-1" style={{ backgroundColor: 'whitesmoke' }}>
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-2 gap-12 mt-12 sm:grid-cols-3 lg:grid-cols-4 sm:mt-16 lg:mt-20">
          <div className="col-span-2 sm:col-span-3 lg:col-span-1 flex flex-col lg:max-w-[306px]">
            <Image link="/" src={logo} alt="logo" className="!h-14 !w-auto" />

            <Typography
              variant="P_Regular_H6"
              className="!mt-4 text-dark-white"
            >
              Our innovative solutions make clean energy possible for households & 
              businesses
            </Typography>
            {/* <Typography variant="P_Regular_H6" className="mt-4 text-dark-white">
              Ph. No:
              <span className="ml-2 text-primary-black">+91 99806 17533</span>
            </Typography> */}
            {/* <Typography variant="P_Regular_H6" className="mt-2 text-dark-white">
              Email:
              <span className="ml-2 text-primary-black">
                sales@abhartech.com
              </span>
            </Typography> */}

            <div className="flex flex-row gap-3 mt-6">
              {/* <Image
                link="/"
                target="_black"
                src={instagramSvg}
                alt="logo"
                className="!h-7 !w-auto"
              />
              <Image
                link="/"
                target="_black"
                src={twitterSvg}
                alt="logo"
                className="!h-7 !w-auto"
              />
              <Image
                link="/"
                target="_black"
                src={googleSvg}
                alt="logo"
                className="!h-7 !w-auto"
              />
              <Image
                link="/"
                target="_black"
                src={facebookSvg}
                alt="logo"
                className="!h-7 !w-auto"
              />
              <Image
                link="https://www.linkedin.com/company/abhartech-technologies-and-services-private-limited"
                target="_black"
                src={linkedinSvg}
                alt="logo"
                className="!h-7 !w-auto"
              /> */}
            </div>
          </div>

          <div className="lg:pl-32">
            <Typography variant="P_SemiBold_H6" className="text-dark-white">
              Links
            </Typography>
            <div className="mt-6 flex flex-col gap-6">
              <Typography
                link="/"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Home
              </Typography>
              {/* <Typography
                link="/company"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Company
              </Typography> */}
              <Typography
                link="/products"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Products
              </Typography>
              <Typography
                link="/consulting"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Services
              </Typography>
              <Typography
                link="/careers"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Careers
              </Typography>
            </div>
          </div>

          <div className="lg:pl-16">
            <Typography variant="P_SemiBold_H6" className="text-dark-white">
              Others
            </Typography>
            <div className="mt-6 flex flex-col gap-6">
              <Typography
                link="/contact-us"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Contact Us
              </Typography>
              <Typography
                link="/contact-us"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Help & Support
              </Typography>
              <Typography
                link="/"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Terms & Conditions
              </Typography>
              <Typography
                link="/"
                variant="P_Regular_H6"
                className="text-dark-white"
                animate="move"
              >
                Privacy Policy
              </Typography>
            </div>
          </div>

          <div className="col-span-2 sm:col-span-1 lg:max-w-[306px] ">
            <Typography variant="P_SemiBold_H6" className="text-dark-white">
              Links
            </Typography>

            <div
              className="flex flex-col w-full gap-6 mt-4"

            >
              {/* <div className="flex flex-col w-full gap-4">

                <EmailInput
                  label="Email address"
                  name="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Button onClick={onSubmit} block>
                  Subscribe Now
                </Button>
              </div> */}
              <Button  link="/contact-us" type="submit" block className='conatct_us' >
                Contact us

                {/* onClick={() => setpopUp(!popUp)} */}
              </Button>
            </div>
          </div>
        </div>

        <div className="py-3 text-center border-t border-gray-200">
          <Typography variant="P_Medium_H6" className="text-dark-white">
          © ABTECH {new Date().getFullYear()}
          </Typography>
        </div>
      </div>
      {popUp && <ContactForm setOpen={setpopUp} />}
    </footer>
  );
}

export default Footer;
