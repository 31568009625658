import React, { useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

// ** import components
import Button from '@shared/Button';
import Typography from '@shared/Typography';

// ** import images
import subtractImg from '@assets/Images/home/TalkToUs/Subtract.png';
import { bottom_top_variants } from '@src/utils/animations';
import { useNavigate } from 'react-router-dom';

function TalkToUs() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const navigate = useNavigate()
  return (
    <section ref={ref} className="bg-primary-white">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="pt-16 text-center flex flex-col max-w-3xl overflow-hidden mx-auto">
          <div className="inline-flex overflow-hidden">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0,
              }}
              variant="P_SemiBold_H3"
              className="text-primary-black"
            >
              ABTECH is a leading provider of enterprise business solutions
            </Typography>
          </div>
          <div className="inline-flex overflow-hidden">
            <Typography
              mAnimate={{
                mVariants: bottom_top_variants,
                isInView: isInView,
                mDelay: 0.1,
              }}
              variant="P_Regular_H6"
              className="mt-2 text-light-black"
            >
              ABTECH has dedicated Center of Excellence building innovative solutions. At ABTECH, we closely monitor changing regulations, customer needs, technology advances and accordingly come up with new ideas and accelerators. This helps us to stay ahead in the industry and provide latest solutions to industry problems.
            </Typography>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center px-16 mt-8 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row lg:mt-12 sm:px-0">
          {/* <div className=" w-fit h-14 overflow-hidden">
            <m.div
              initial="initial"
              animate={isInView && 'visible'}
              custom={0.2}
              variants={bottom_top_variants}
              viewport={{ once: true }}
              className="max-w-fit"
            >
              <Button onClick={() => navigate('/contact-us')}>Talk to us</Button>
            </m.div>
          </div> */}
          {/* <div className=" w-fit h-14 overflow-hidden">
            <m.div
              initial="initial"
              animate={isInView && 'visible'}
              custom={0.3}
              variants={bottom_top_variants}
              viewport={{ once: true }}
              className="max-w-fit"
            >
              <Button link={'/products'} variant="outline">Product Demo</Button>
            </m.div>
          </div> */}
        </div>
      </div>
      <img
        src={subtractImg}
        alt="subtractImg"
        className="md:-mt-8 -z-0 lg:-mt-16 w-screen"
      />
    </section>
  );
}

export default TalkToUs;
