import { useState, useRef } from 'react';

import { motion as m, useInView } from 'framer-motion';

//** importing the component */
import Typography from '@src/components/shared/Typography';

//**importing images */
import Image from '@shared/Image';
import dashboard from '@assets/svg/home/Billing.png';
import dashboard1 from '@assets/svg/home/Missing reads.png';
import {
  bottom_top_variants,
  top_bottom_variants,
} from '@src/utils/animations';
import OurServices from '@src/components/OurServices';
import msgThumpSubSvg from '@assets/svg/home/msgThumpSub.svg';
import mobileShopSvg from '@assets/svg/home/mobileShop.svg';
import socialMsgSvg from '@assets/svg/home/socialMsg.svg';
import startGiftSvg from '@assets/svg/home/startGift.svg';
import globalNetworkSvg from '@assets/svg/home/globalNetwork.svg';
import customerSupportSvg from '@assets/svg/home/customerSupport.svg';
import ProductHighlights from '../Home/ProductHighlights';
import Costumers from '../Home/Costumers';
import Testimonials from '@src/components/Testimonials';
import SolutionEnSmart from './SolutionEnSmart';

import bulbImg from '@assets/Images/bg/e4.jpg';
import Banner from '@assets/Images/bg/Mprosmart.jpg';



const servicesData = [
  {
    img: msgThumpSubSvg,
    title: 'Billing & Collection Operations',
    description:
      'The core application allows Utilities to record meter reading, Generate Bills & Collect Bill Payments thereby providing a transparency.',
  },
  {
    img: mobileShopSvg,
    title: 'Field workers and Supervisors',
    description:
      'The field users get their respective dashboards with details about Pending Jobs, Approvals, Service Orders, Appointments, Scheduling and routing etc.',
  },
  {
    img: socialMsgSvg,
    title: 'Reduces Billing and Collection Costs',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: startGiftSvg,
    title: 'Optimized operations for greater productivity.',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: globalNetworkSvg,
    title: 'Effective Complaints and issue tracking',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: customerSupportSvg,
    title: '100% Transparency & Openness System',
    description:
      'Loremm ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
];
const BenifitsData = [
  {
    img: msgThumpSubSvg,
    title: 'Billing & Collection Operations',
    description:
      'The core application allows Utilities to record meter reading, Generate Bills & Collect Bill Payments thereby providing a transparency.',
  },
  {
    img: mobileShopSvg,
    title: 'Field workers and Supervisors',
    description:
      'The field users get their respective dashboards with details about Pending Jobs, Approvals, Service Orders, Appointments, Scheduling and routing etc.',
  },
  {
    img: socialMsgSvg,
    title: 'Reduces Billing and Collection Costs',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: startGiftSvg,
    title: 'Optimized operations for greater productivity.',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: globalNetworkSvg,
    title: 'Effective Complaints and issue tracking',
    description:
      'Lorem ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
  {
    img: customerSupportSvg,
    title: '100% Transparency & Openness System',
    description:
      'Loremm ipsum dolor sit amet consectetur Interdum id augue sed sed lectus dolor mauris at. Odio ac morbi nisl interdum pellentesque non.',
  },
];

function EnSmart() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isactive, setIsActive] = useState(0);

  const links = [
    'Timekeep',
    'File Storages',
    
  ];

  return (
    // <div className="overflow-x-hidden ">


      <section ref={ref} className="pt-12   sm:pt-0">
<img src={Banner} width='100%' className='w-[100rem] h-[36rem]'/>

        {/* <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 mb-14">
          <div className="max-w-2xl mx-auto text-center">
            <div className=" overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: bottom_top_variants,
                  isInView: isInView,
                  mDelay: 0,
                }}
                variant="P_Bold_H1"
                className="mt-5 !text-5xl block leading-tight text-mid-black sm:leading-tight lg:leading-tight"
              >
                EnSmart
              </Typography>
            </div>
            <div className=" overflow-hidden">
              <Typography
                mAnimate={{
                  mVariants: bottom_top_variants,
                  isInView: isInView,
                  mDelay: 0.1,
                }}
                variant="P_Medium_H5"
                className="text-mid-black mx-16  flex justify-center py-2 mt-2"
              >
                ABTECH has made big investment in Solution Development for Electricity, Water & Gas Utilities. The vision is to completely Digitize Utilities and provide its benefits to our utility customers.
                ABTECH has dedicated Center of Excellence building innovative solutions for its customers. We closely monitor changing regulations, customer needs, technology advances and accordingly come up with new ideas and accelerators. This helps us to stay ahead in the industry and provide latest solutions to problems which Utilities face, even before they realize that this becomes a necessity. Our solutions are flexible and adaptable in nature and can be easily enhanced and upgraded.
              </Typography>
            </div>
          </div>
        </div> */}

        

        <OurServices
          servicesData={[]}
          title={'EnSmart'}
          description={
            ' ABTECH has made big investment in Solution Development for Electricity, Water & Gas Utilities. The vision is to completely Digitize Utilities and provide its benefits to our utility customers.ABTECH has dedicated Center of Excellence building innovative solutions for its customers. We closely monitor changing regulations, customer needs, technology advances and accordingly come up with new ideas and accelerators. This helps us to stay ahead in the industry and provide latest solutions to problems which Utilities face, even before they realize that this becomes a necessity. Our solutions are flexible and adaptable in nature and can be easily enhanced and upgraded.'
          }
        />

        <div className="flex justify-center flex-wrap  text-mid-black cursor-pointer md:overflow-hidden overflow-x-auto mb-5">
          {links.map((link, i) => (
            <m.div
              initial="initial"
              animate={isInView && 'visible'}
              variants={top_bottom_variants}
              custom={i * 0.07 + 0.2}
              viewport={{ once: true }}
              onClick={() => setIsActive(i)}
              className={`px-4 py-2 mx-6 ${
                isactive === i
                  ? ' border-b-[1px] border-b-primary-blue text-primary-blue'
                  : ''
              }`}
            >
              {link}
            </m.div>
          ))}
        </div>

        <div className="bg-primary-white">
          <div className="relative w-full">
            <div className="relative mx-auto">
              <div className="lg:max-w-6xl lg:mx-auto overflow-hidden">
                <m.div
                  initial="initial"
                  animate={isInView && 'visible'}
                  variants={bottom_top_variants}
                  custom={0.6}
                  viewport={{ once: true }}
                  className=""
                >
                  <Image
                    link="/"
                    className="transform-scale-110"
                    src={isactive % 2 === 0 ? dashboard : dashboard1}
                    alt=""
                  />
                </m.div>
              </div>
            </div>
          </div>
        </div>
        <SolutionEnSmart />
        {/* <OurServices
          servicesData={[]}
          title={'Solution'}
          description={
            'SmartEU is an Integrated Enterprise solution comprising of Web Based Application, Mobility Solutions and Smart Devices for various stakeholders that is useful for Utility Companies, Consumers and Outsourced Agencies. Apart from the inbuilt workflows, SmartEU offers inbuilt Dashboards and Reporting Framework and helps users to generate/export Reports & analyze Data'
          }
        /> */}
        <OurServices
          servicesData={servicesData}
          title={'Features'}
          description={
            'ABTECH’s EnSmart is a First-of-its-Kind Cloud-Based Integrated Solution catering to the needs of  Water, Electricity and Gas Utilities. It helps in Managing Business Operations, Metering and Billing Information, People & Assets.  ABTECH’s EnSmart solution an exclusive-fit for Smart Cities initiative.'
          }
        />
        <OurServices
          servicesData={BenifitsData}
          title={'Benifits'}
          description={
            'ABTECH’s EnSmart is a First-of-its-Kind Cloud-Based Integrated Solution catering to the needs of  Water, Electricity and Gas Utilities. It helps in Managing Business Operations, Metering and Billing Information, People & Assets.  ABTECH’s EnSmart solution an exclusive-fit for Smart Cities initiative.'
          }
        />
        {/* <Costumers /> */}
        {/* <Testimonials /> */}
      </section>
    // </div>
  );
}
export default EnSmart;
