import React from 'react';

//**import assets */
import { Stars } from '@src/assets/svg/icons';

//**import shared components */
import Typography from '@src/components/shared/Typography';

//**import  components */
import CircularProfile from './CircularProfile';

const teamMembers = [
  {
    name: 'Yusra Aziz',
    role: 'Business Analyst',
    imageUrl:
      'https://landingfoliocom.imgix.net/store/collection/saasui/images/team/1/avatar-female-2.png',
  },
  {
    name: 'John Doe',
    role: 'Software Engineer',
    imageUrl:
      'https://landingfoliocom.imgix.net/store/collection/saasui/images/team/1/avatar-female-3.png',
  },
  {
    name: 'Jane Smith',
    role: 'Project Manager',
    imageUrl:
      'https://landingfoliocom.imgix.net/store/collection/saasui/images/team/1/avatar-female-1.png',
  },
  {
    name: 'Emily Davis',
    role: 'UI/UX Designer',
    imageUrl:
      'https://landingfoliocom.imgix.net/store/collection/saasui/images/team/1/avatar-female-2.png',
  },
  {
    name: 'Michael Brown',
    role: 'Data Scientist',
    imageUrl:
      'https://landingfoliocom.imgix.net/store/collection/saasui/images/team/1/avatar-male-1.png',
  },
  {
    name: 'Kyle Johnson',
    role: 'Frontend Developer',
    imageUrl:
      'https://landingfoliocom.imgix.net/store/collection/saasui/images/team/1/avatar-male-2.png',
  },
  {
    name: 'Olivia Lee',
    role: 'Backend Developer',
    imageUrl:
      'https://landingfoliocom.imgix.net/store/collection/saasui/images/team/1/avatar-female-3.png',
  },
];

function Team() {
  return (
    <section className="pt-8 overflow-hidden bg-light-blue">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-lg mx-auto text-center">
          <div className="flex justify-center">
            <Typography variant="P_Bold_H2" className="text-mid-black">
              We
            </Typography>
            <span className="p-2 ">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 107.39"
                className="w-10 md:w-16 md:h-14"
              >
                <title>red-heart</title>
                <path
                  className="cls-1"
                  d="M60.83,17.18c8-8.35,13.62-15.57,26-17C110-2.46,131.27,21.26,119.57,44.61c-3.33,6.65-10.11,14.56-17.61,22.32-8.23,8.52-17.34,16.87-23.72,23.2l-17.4,17.26L46.46,93.55C29.16,76.89,1,55.92,0,29.94-.63,11.74,13.73.08,30.25.29c14.76.2,21,7.54,30.58,16.89Z"
                  style={{
                    fill: '#ed1b24',
                    fillRule: 'evenodd',
                  }}
                />
              </svg>
            </span>
            <Typography variant="P_Bold_H2" className="text-primary-black">
              Abtech
            </Typography>
          </div>
          <Typography variant="P_Regular_H6" className="mt-4 text-mid-black ">
            
          </Typography>
        </div>
      </div>
      <div className="flex justify-center items-center pt-12 pb-5">
        <Stars size={50} />
        <Stars size={50} />
        <Stars size={50} />
        <Stars size={50} />
        <Stars size={50} />
      </div>
      <div>
        <Typography
          variant="P_Regular_H6"
          className="text-mid-black block text-center w-1/3 mx-auto pb-12"
        >
          A nurturing workplace that fosters growth, inclusivity, and
          innovation. Proud to be part of this exceptional family.
        </Typography>
      </div>

      {/* <CircularProfile/> */}
      {/* <div>
        <div className="relative z-10 flex justify-center w-full py-12 gap-5 -space-x-16 overflow-x-hidden overflow-y-visible md:-mb-4 xl:-space-x-24 ">
          {teamMembers.map((member, index) => (
            <CircularProfile
              key={index}
              name={member.name}
              role={member.role}
              imageUrl={member.imageUrl}
              index={index}
              middleIndex={Math.floor(teamMembers.length / 2)}
            />
          ))}
        </div>
      </div> */}
    </section>
  );
}

export default Team;
