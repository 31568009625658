import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { motion as m } from 'framer-motion';

// ** import logo
import logo from '@assets/icon/navLogo.png';

// ** import Icons
import { Icon } from '@iconify/react';

// ** import Button
import Image from '@shared/Image';
import Button from '@shared/Button';
import Typography from '@shared/Typography';
import { top_bottom_variants } from '@src/utils/animations';

function Navbar() {
  const location = useLocation();

  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showProductDropdown, setProductDropdown] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);

  const handleSetNav = () => {
    setShowMobileNav(!showMobileNav);
  };

  const handleMouseEnter = (link) => {
    setHoveredLink(link);
  };

  // Function to reset the hovered link
  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  return (
    <div>
      <header
        style={{ boxShadow: '2px 2px 10px #ddd' }}
        className="py-3 fixed w-[100%] bg-white !z-50"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="!flex !items-center !justify-between">
            <div className="shrink-0  min-w-40 min-h-16 overflow-x-hidden">
              <m.div
                initial={{ x: '-100%' }}
                animate={{ x: 0 }}
                transition={{
                  duration: 1,
                  ease: [0.76, 0, 0.24, 1],
                }}
              >
                <img
                  // link="/"
                  src={logo}
                  alt="logo"
                  className="w-[6rem] h-[6rem]"
                />
              </m.div>
            </div>

            <div className="hidden lg:flex lg:items-center">
              <m.div
                initial="initial"
                animate="visible"
                custom={0.2}
                variants={top_bottom_variants}
              >
                <Button link="/" variant="solid">
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-black'
                    }`}
                  >
                    Home
                  </Typography>
                </Button>
              </m.div>

              {/* <m.div
                initial="initial"
                animate="visible"
                custom={0.3}
                variants={top_bottom_variants}
              >
                <Button link="/company" variant="solid">
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/company'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-black'
                    }`}
                  >
                    Company
                  </Typography>
                </Button>
              </m.div> */}

              <m.div
                initial="initial"
                animate="visible"
                custom={0.4}
                variants={top_bottom_variants}
                onMouseEnter={() => setProductDropdown(true)}
                onMouseLeave={() => setProductDropdown(false)}
              >
                <Button link="/products" variant="solid">
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/products'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-black'
                    }`}
                  >
                    Industry Solution
                  </Typography>
                </Button>

                {showProductDropdown && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      backgroundColor: '#f9f9f9',
                      minWidth: '220px',
                      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                      zIndex: 1,
                    }}
                  >
                    {/* Add your dropdown links here, styling them inline as well */}
                    <a
                      href="smarteu"
                      style={{
                        color: hoveredLink === 'SmartEU' ? 'blue' : 'black',
                        padding: '12px 16px',
                        textDecoration: 'none',
                        display: 'block',
                        backgroundColor:
                          hoveredLink === 'product1' ? '#ddd' : 'transparent',
                      }}
                      onMouseEnter={() => handleMouseEnter('product1')}
                      onMouseLeave={handleMouseLeave}
                    >
                      Utilities
                    </a>
                    {/* <a
                      href="ensmart"
                      style={{
                        color: hoveredLink === 'EnSmart' ? 'blue' : 'black',
                        padding: '12px 16px',
                        textDecoration: 'none',
                        display: 'block',
                        backgroundColor:
                          hoveredLink === 'product2' ? '#ddd' : 'transparent',
                      }}
                      onMouseEnter={() => handleMouseEnter('product2')}
                      onMouseLeave={handleMouseLeave}
                    >
                      Metering
                    </a> */}
                    <a
                      href="mprosmart"
                      style={{
                        color: hoveredLink === 'mProSMART' ? 'blue' : 'black',
                        padding: '12px 16px',
                        textDecoration: 'none',
                        display: 'block',
                        backgroundColor:
                          hoveredLink === 'product3' ? '#ddd' : 'transparent',
                      }}
                      onMouseEnter={() => handleMouseEnter('product3')}
                      onMouseLeave={handleMouseLeave}
                    >
                      Capital Project Mgmt
                    </a>
                    {/* Add more links as needed */}
                  </div>
                )}
              </m.div>

              <m.div
                initial="initial"
                animate="visible"
                custom={0.5}
                variants={top_bottom_variants}
                onMouseEnter={() => setShowServicesDropdown(true)}
                onMouseLeave={() => setShowServicesDropdown(false)}
              >
                <Button link="/consulting" variant="solid">
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/consulting'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-black'
                    }`}
                  >
                    Services
                  </Typography>
                </Button>
                {showServicesDropdown && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      backgroundColor: '#f9f9f9',
                      minWidth: '220px',
                      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                      zIndex: 1,
                    }}
                  >
                    {/* Add your dropdown links here, styling them inline as well */}
                    <a
                      href="amisp"
                      style={{
                        color: hoveredLink === 'AMISP' ? 'blue' : 'black',
                        padding: '12px 16px',
                        textDecoration: 'none',
                        display: 'block',
                        backgroundColor:
                          hoveredLink === 'service1' ? '#ddd' : 'transparent',
                      }}
                      onMouseEnter={() => handleMouseEnter('service1')}
                      onMouseLeave={handleMouseLeave}
                    >
                      AMISP
                    </a>
                    <a
                      href="consulting"
                      style={{
                        color: hoveredLink === 'Consulting' ? 'blue' : 'black',
                        padding: '12px 16px',
                        textDecoration: 'none',
                        display: 'block',
                        backgroundColor:
                          hoveredLink === 'service2' ? '#ddd' : 'transparent',
                      }}
                      onMouseEnter={() => handleMouseEnter('service2')}
                      onMouseLeave={handleMouseLeave}
                    >
                      Consulting
                    </a>
                    {/* Add more links as needed */}
                  </div>
                )}
              </m.div>

              <m.div
                initial="initial"
                animate="visible"
                custom={0.6}
                variants={top_bottom_variants}
              >
                <Button link="/careers" variant="solid">
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/careers'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-black'
                    }`}
                  >
                    Careers
                  </Typography>
                </Button>
              </m.div>
            </div>

            {showMobileNav && (
              <div className="z-10 pl-10 pt-20 flex flex-col justify-start lg:hidden fixed top-0 left-0 w-full h-full text-white bg-black lg:items-center">
                <Button
                  link="/"
                  variant="solid"
                  position="start"
                  onClick={handleSetNav}
                >
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-white'
                    }`}
                  >
                    Home
                  </Typography>
                </Button>

                <Button
                  link="/company"
                  variant="solid"
                  position="start"
                  onClick={handleSetNav}
                >
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/company'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-white'
                    }`}
                  >
                    Company
                  </Typography>
                </Button>

                <Button
                  link="/products"
                  variant="solid"
                  position="start"
                  onClick={handleSetNav}
                >
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/products'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-white'
                    }`}
                  >
                    Products
                  </Typography>
                </Button>

                <Button
                  link="/services"
                  variant="solid"
                  position="start"
                  onClick={handleSetNav}
                >
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/services'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-white'
                    }`}
                  >
                    Services
                  </Typography>
                </Button>

                <Button
                  link="/careers"
                  variant="solid"
                  position="start"
                  onClick={handleSetNav}
                >
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/careers'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-white'
                    }`}
                  >
                    Careers
                  </Typography>
                </Button>

                <Button
                  link="/contact-us"
                  variant="solid"
                  position="start"
                  onClick={handleSetNav}
                >
                  <Typography
                    variant="P_Regular_H6"
                    className={` ${
                      location.pathname === '/contact-us'
                        ? 'text-primary-blue !font-semibold'
                        : 'text-primary-white'
                    }`}
                  >
                    Contact Us
                  </Typography>
                </Button>
              </div>
            )}

            <m.div
              initial="initial"
              animate="visible"
              custom={0.7}
              variants={top_bottom_variants}
              className="hidden sm:flex sm:items-center sm:justify-end sm:space-x-4"
            >
              <Button link="/contact-us">Contact Us</Button>
            </m.div>

            <div className="flex ml-4 lg:hidden z-[99999]">
              <Button
                shape="circle"
                variant="solid"
                onClick={() => setShowMobileNav(!showMobileNav)}
                className={`!p-2 ${
                  showMobileNav && '!text-primary-green !bg-primary-white'
                } inline-flex items-center p-2.5 text-primary-green duration-200 border-2 border-primary-blue rounded-full hover:bg-primary-white hover:text-primary-black transition-all`}
              >
                {showMobileNav ? (
                  <Icon icon="ion:close" />
                ) : (
                  <Icon icon="iconamoon:menu-burger-horizontal-bold" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
